/** @jsx jsx */
import {Box, jsx,Grid, Link as ThLink,Text} from 'theme-ui'
//import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'
import {GatsbyImage} from 'gatsby-plugin-image'
import { graphql,Link } from 'gatsby'






export default function ErsterSalon({data}){
return(
  <Layout>
        <h2 sx={{
              fontWeight:300,
              textDecoration: `none`,
              fontSize:[3,5,6],
              color:`text`,
             
             
          }} mb="30px">Salon N° 1</h2>
    <MySEO
     title="Der erste Salon von K.0.M in St. Goarshausen"
     
    />
    <Box style={{maxWidth:'900px'}}>
    <Text>
<strong>Salon N° 1</strong> fand am <u>24. Juli in der Galerie Stadtmühle in St. Goarshausen</u> statt.
In dem historischen und sehr atmosphärischen Gebäude befinden sich die 
Atelier- und Ausstellungsräume der ortsansässigen Malerin <strong>Evelyn Sattler</strong>. 
</Text>

<Text sx={{py:3}}>
  <strong>Salon N° 1</strong> stand mit <strong>Peter Klohmann</strong> am Saxophon und <strong>Frank Reichert</strong> am E-Piano im Zeichen des Jazz.
</Text>
  <Text>&rarr; <a sx={{color:'text'}} rel="noopener noreferrer" target="_blank" href="pdf/KOM_Salon1.pdf">Flyer zum Salon 1 / Download</a>
  </Text>
    </Box>
    
    <Grid sx={{marginTop:60}} columns={[1,2]}>
    <Box>

    <GatsbyImage image={data.saloneins11.childImageSharp.gatsbyImageData} alt="Eingang Veranstaltungsort" /> 
<p sx={{color:'text',lineHeight:'1.1',mt:2 ,mb:3,fontWeight:300,fontStyle:'italic',fontSize:0}}>Peter Klomann</p>
 <GatsbyImage image={data.saloneins1.childImageSharp.gatsbyImageData} alt="Eingang Veranstaltungsort" /> 
<p sx={{color:'text',lineHeight:'1.1',mt:2 ,mb:3,fontWeight:300,fontStyle:'italic',fontSize:0}}>Salon N° 1 fand in der Galerie Stadtmühle in St. Goarshausen von Evelyn Sattler statt.</p>
<GatsbyImage image={data.saloneins2.childImageSharp.gatsbyImageData} alt="Die Musiker" />

<p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Peter Klohmann am Saxophon und Frank Reichert am E-Piano</p>

</Box>
<Box>
  <GatsbyImage image={data.saloneins3.childImageSharp.gatsbyImageData} alt="Konzertimpression" />
<p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Zur Veranstaltung erschienen viele Kulturinteressierte der Region.</p>
{/* 
<GatsbyImage image={data.saloneins4.childImageSharp.gatsbyImageData} alt="Salonbild Blüchermuseum" />
<p sx={{color:'text',lineHeight:'1', mt:2 ,pb:3,fontWeight:300,fontStyle:'italic', fontSize:0}}>Salon N° 2 soll im Blüchermuseum, in Kaub  stattfinden. Wir möchten uns um die Finanzierung einer Sanierung des dortigen Flügels bemühen. </p>  */}
<GatsbyImage image={data.sgalerie.childImageSharp.gatsbyImageData} alt="Konzertimpression" />
<p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Galerie Stadtmühle in St. Goarshausen</p>
</Box>
<Link style={{paddingBottom:50}} to="/salons"><ThLink>&rarr; Salons</ThLink></Link>
    </Grid>
    </Layout>
)
}


export const ErsterSalonQuery = graphql`
  query {

    saloneins1:file(relativePath: { eq: "salons/salon1-1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    saloneins2:file(relativePath: { eq: "salons/salon1-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
      
    }
    saloneins3:file(relativePath: { eq: "salons/salon1-3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    saloneins11:file(relativePath: { eq: "salons/klomann.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    sgalerie:file(relativePath: { eq: "salons/stadtgalerie-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
  }
`